
.lead-position {
  position: absolute;
  top: 3px;
  right: 3px;

}

.lead {
  width: 1em;
  padding-top: 2px;
  padding-bottom: 0px;
  padding-left: 3px;
  padding-right: 3px;
  font-size:smaller;

  border: 1px solid;
  border-radius: 5px;
  border-color: rgb(66, 66, 66);
  background-color: rgb(245, 245, 245);

}
