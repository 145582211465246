.wrapper-mobile {
  grid-template-columns: 3em 4em 0em repeat(10, [col] 1fr);

}
.wrapper-desktop {
  grid-template-columns: 3em 4em 5em repeat(10, [col] 1fr);

}

.wrapper {
    display: grid;
    height: 60em;
    grid-template-rows: repeat(30, 1fr);

    grid-column-gap: 0.3em;
    grid-row-gap: 0.1em;
    font-family: Helvetica, Arial, sans-serif;
    min-width: 800px;
  }


.rollover-hint {
  grid-row-start: 0;
  grid-row-end: 0;
  grid-column-start: 4;
  grid-column-end: 13;
  text-align: right;
  font-size: small;
  position:relative;
}
.rollover-hint-txt {
  position: absolute;
  bottom: -2px;
  right: 0px;
}