.department {
 
    border-radius: 10px;
    border: 1px solid grey;
    padding: 0px 4px;
    pointer-events:auto;
    padding: 5px;
    top: 50%;
  }

  .department-mobile {
 
    border-radius: 10px;
    border: 1px solid grey;
    padding: 0px 4px;
    pointer-events:auto;
    padding: 5px;
    font-size:small;
  }