
.info-grid {
  position: relative;

}

.info {
  font-weight: bold;
  font-size: large;
  background-color: rgb(76, 154, 255);
  border-radius: 1.2rem 1.2rem 1.2rem 0rem ;
  transform: rotate(35deg);
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  bottom: -1.0rem;
  color: white;
}
.i {
  font-family: sans-serif;
  position: relative;
  left:1px;
  bottom: 1px;
  transform: rotate(-35deg);
  text-align: center;
}
.info-pos {

  transform: rotate(-35deg);
}

.info:hover {
  background: #d9d9d9ff;
  transition: background .35s ease-in-out;
  color: rgb(0, 0, 0);
}

.info-text {
  border-radius: 1rem ;
  position:absolute;
  top: 3em;
  width: 10em;
  border: 1px solid rgb(110, 172, 252);
  background-color: rgb(209, 209, 209);
  transition: all 0.5s linear;
  opacity: 0;
  font-size: 16px;
  font-weight: normal;
  padding: 5px;
}

.info-grid:hover .info-text {
  opacity: 1;
}
