.employer {
    border-radius: 5px;
    border: 1px solid #333333;
    pointer-events: auto;
    padding: 5px;
  }
  
  .employer-mobile {
    border-radius: 5px;
    border: 1px solid #333333;
    pointer-events: auto;
    padding: 5px;
    font-size:small;
  }