

.twrapper {
  position:relative;
  display: block;
  left: 5%;
  width: 80%;
}

.employerSection {
  position:relative;
  display: block;
  left: 2%;
}

.orgSection {
  position:relative;
  display: block;
  left: 2%;
}

.projectHeader span {
  margin-right: 1em;
}
.projectTitle {
  font-size: large;
  padding-right: 1em;
  font-weight: bold;
}

.address {
  font-size: large;
}

.markdown ul {
  font-weight: normal;
}
.markdown li {
  margin-bottom: 0px;
}