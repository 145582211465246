  .header {
    transition: all 0.25s linear;
    color: rgb(0, 0, 0);
    font-weight: normal;
    opacity: 1;
  }
  .header-desktop {
    font-size: medium;
  
  }
  .header-mobile {
    font-size: small;
  }
  .project:hover .header {
    opacity: 0;
  }

  .header-overlay {
    position: absolute;
    top: 0.25em;
    transition: all 0.25s linear;
    font-weight: normal;
    color: rgb(105, 105, 105);
    opacity: 0;
  }

  .project:hover .header-overlay {
    opacity: 1;
  
  }

  lead-position {
    position: absolute;
    top: 3px;
    right: 3px;
  
  }
  .modal-header {
    font-weight: bold;
  }
  .project {
    position: relative;
    border-radius: 5px;
    border: 1px solid grey;
    background: #d9d9d9C0;
    padding: 4px;
  }
  
  .project:hover {
    background: #d9d9d9ff;
    transition: background .35s ease-in-out;
  }

  .project-summary {
    transition: opacity 0.5s linear;
    opacity: 0;
    font-weight: normal;
    pointer-events: auto;
  }
  
  
  .project:hover .project-summary {
    opacity: 1;
  }

  .project-more {
    position: absolute;
    right:0;
    bottom:0;
  }

  .project-preview-mobile {
    font-size: small;
    font-weight: bold;

  }

  .project-preview-desktop {
    font-size: 14px;
  }

  .project-preview {
    position: absolute;
    top:2em;
    transition: opacity 0.5s linear;
    opacity: 1;
    font-weight: normal;
    color: rgb(105, 105, 105);
    pointer-events: none;
  }
  
  
  .project:hover .project-preview {
    opacity: 0;
  }

  .modal {
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    min-height: 50%;
    max-height: 90%;
    top: 25%;
    background-color: rgb(255, 255, 255); /* Fallback color */
    transition: all 0.5s linear; 
    border-radius: 5px; 
    border: 1px solid #888;
    overflow-wrap: break-word;
    pointer-events: auto;
    overflow-y:scroll;
  }

  .modal-mobile {
    width: 80%;
    left: 10%;
    
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    min-height: 50%;
    max-height: 90%;
    top: 5%;
    background-color: rgb(255, 255, 255); /* Fallback color */
    transition: all 0.5s linear; 
    border-radius: 5px; 
    border: 1px solid #888;
    overflow-wrap: break-word;
    pointer-events: auto;
    overflow-y:scroll;
  }

  .modal-desktop {
    width: 50%;
    left: 25%;
    
    display: block;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    min-height: 50%;
    max-height: 90%;
    top: 25%;
    background-color: rgb(255, 255, 255); /* Fallback color */
    transition: all 0.5s linear; 
    border-radius: 5px; 
    border: 1px solid #888;
    overflow-wrap: break-word;
    pointer-events: auto;
    overflow-y:scroll;
  }

  .modal-overlay {
    position: fixed; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(58, 58, 58, 0.5); /* color */
    display:block; /* making it hidden by default */ 
    pointer-events: auto;
  }

  .modal-content {
    margin: 2em;
  }

  .modal-content > .header {
    width: 100%;
    border-bottom: 1px solid rgb(43, 41, 41);
    text-align: center;
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .modal-content > .close {
    position: absolute;
    display: inline-block;
    right: 5px;
    top: -15px;
    color: #aaa;
  
    font-size: 40px;
    font-weight: bold;
  }
  
  .modal-content > .close:hover,
  .modal-content > .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-overlay {
    animation: overlay 1.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-2%);
  transition: all 600ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0.01;
  transform: translateX(2%);
}